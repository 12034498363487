import React from "react";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import { useCryptohubTransactionRequest } from "./CryptohubPaymentQueries";
import {OTPVerification} from "../../public/reusables/OTPVerification";

type T_ViewProps = {
  paymentState: T_UseCryptohubPaymentState;
};

export const SimpleKycVerifyOtp = ({ paymentState }: T_ViewProps) => {
  const transactionDetails = useCryptohubTransactionRequest(
    paymentState.transactionId
  );
  return (
    <OTPVerification
      apiURL={"customers"}
      paymentState={paymentState}
      transactionId={transactionDetails.result?.sender_id}
      onSuccessUpdate={"isSimpleKycOtpVerified"}
    />
  );
}
