import React, {ChangeEvent, useState} from "react";
import {Avatar, Box, Button} from "@mui/material";
import { useStyles } from "./settingStyle";
import PersonIcon from "@mui/icons-material/Person";
import {pallete} from "../../../../app/styles";

const ChangeImageForm = ({ userDetails, handleImageUpload }:any) => {
  const { classes } = useStyles();

  const ProfileImageSelector = ({ imageSrc, onImageChange }: { imageSrc: string, onImageChange: (file: File) => void }) => {
  const { classes } = useStyles();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      onImageChange(file);
    }
  };

  const CurrentImage = () => {
    if (selectedImage) {
      return (
        <Avatar
          src={selectedImage || undefined}
          alt="Selected Profile"
          className={classes.avatar}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    } else if (imageSrc) {
      return (
        <Avatar
          src={imageSrc}
          alt="Selected Profile"
          className={classes.avatar}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    } else {
      return (
        <PersonIcon
          sx={{
            fontSize: "100px",
            color: pallete.primaryWhite,
            cursor: "pointer",
          }}
          onClick={() => document.getElementById("imageInput")?.click()}
        />
      );
    }
  };

  return (
    <Box>
      <CurrentImage />
      <input
        type="file"
        id="imageInput"
        accept="image/*"
        className={classes.inputFile}
        onChange={handleImageChange}
      />
    </Box>
  );
};

  return (
    <Box>
        <Box className={classes.personIconBox}>
            <ProfileImageSelector imageSrc={userDetails.image} onImageChange={handleImageUpload} />
        </Box>
        <Button
            variant="contained"
            className={classes.deletImgBtn}
            startIcon={
              <Box
                component="img"
                className={classes.deletIcon}
                src="assets/icons/delete.svg"
                alt="Delete Icon"
          />
        }
      >
        Delete Photo
      </Button>
    </Box>
  );
};
export default ChangeImageForm ;