import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import { Formik } from "formik";
import Loader from "app/components/unsorted/Loader";
import MessageAlert from "app/components/unsorted/MessageAlert";
import * as Yup from "yup";
import { SearchInput } from "app/components/inputs";
import { TabContent } from "app/components/tabs";
import { uid } from "chart.js/dist/helpers/helpers.core";
import { create } from "domain";

const form_schema = Yup.object().shape({
  userAccount: Yup.string().required("User Account is required"),
  currency: Yup.string().required("Currency is required"),
  exchangeAccount: Yup.string().required("Exchange Account is required"),
  asset: Yup.string().required("Asset is required"),
  quantity: Yup.number().required("Quantity is required"),
  bestPrice: Yup.number().required("Best Price is required"),
  type: Yup.number().required("Type is required"),
  fees: Yup.number().required("Fees is required"),
});

export const ContactOTCForm = ({ heading, onSubmit }: any) => {
  // State for form fields
  const { classes } = useStyles();
  const [users, setUsers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  // Handle form submission
  const handleAPIcall = async (values: any) => {
    const payload = {
      user_account_id: values.userAccount,
      asset_id: values.asset,
      currency_id: values.currency,
      exchange_account_id: values.exchangeAccount,
      quantity: parseFloat(values.quantity),
      type: values.type,
      best_price: parseFloat(values.bestPrice),
      fees: parseFloat(values.fees),
    };
    onSubmit(payload);
  };

  const types = [
    {
      id: 0,
      type: "Sell",
    },
    {
      id: 1,
      type: "Buy",
    },
  ];

  const getAsset = async () => {
    setLoading(true);
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setAssets(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getUser = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setUsers(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setCurrencies(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getExchange = async () => {
    setLoading(true);
    await getAxiosAPI("/exchange", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setExchanges(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAsset();
    getCurrency();
    getExchange();
    getUser();
  }, []);

  return (
    <Box className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h3" className={classes.formHeading}>
        {heading}
      </Typography>
      <Formik
        initialValues={{
          userAccount: "",
          asset: "",
          currency: "",
          exchangeAccount: "",
          quantity: "",
          type: "",
          bestPrice: "",
          fees: "",
        }}
        validationSchema={form_schema}
        onSubmit={(values: any) => {
          handleAPIcall(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }: any) => (
          <Grid container>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={users}
                label="Select User Account"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="email"
                formikValue="userAccount"
              />
              {touched.userAccount && errors.userAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.userAccount}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={currencies}
                label="Select Currency"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="symbol"
                formikValue="currency"
              />
              {touched.currency && errors.currency && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.currency}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={exchanges}
                label="Select Exchange"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="exchangeAccount"
              />
              {touched.exchangeAccount && errors.exchangeAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.exchangeAccount}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <SearchInput
                options={assets}
                label="Select Asset"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="asset"
              />
              {touched.asset && errors.asset && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.asset}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="Quantity"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.quantity}
                onChange={handleChange("quantity")}
                onBlur={handleBlur("quantity")}
              />
              {touched.quantity && errors.quantity && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantity}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="Best Price"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.bestPrice}
                onChange={handleChange("bestPrice")}
                onBlur={handleBlur("bestPrice")}
              />
              {touched.bestPrice && errors.bestPrice && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.bestPrice}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <SearchInput
                options={types}
                label="Select Type"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="type"
                formikValue="type"
              />
              {touched.type && errors.type && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.type}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="fees"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.fees}
                onChange={handleChange("fees")}
                onBlur={handleBlur("fees")}
              />
              {touched.fees && errors.fees && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.fees}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBox}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
    // <Box className={classes.mainBox}>
    // </Box>
  );
};

const form_schema1 = Yup.object().shape({
  uid: Yup.string().required("UID is required"),
  userAccount: Yup.string().required("User Account is required"),
  vault: Yup.string().required("User Vault is required").strict(true),
  currency: Yup.string().required("Currency is required"),
  exchangeAccount: Yup.string().required("Exchange Account is required"),
  asset: Yup.string().required("Asset is required"),
  quantity: Yup.number().required("Quantity is required"),
  bestPrice: Yup.number().required("Best Price is required"),
  type: Yup.number().required("Type is required"),
  fees: Yup.number().required("Fees is required"),
  createdAt: Yup.string().required("Created At is required"),
});

export const ContactOTCForm1 = ({ heading, onSubmit }: any) => {
  // State for form fields
  const { classes } = useStyles();
  const [users, setUsers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [userVaults, setUserVaults] = useState([]);

  // Handle form submission
  const handleAPIcall = async (values: any) => {
    const payload = {
      uid: values.uid,
      user_account_id: values.userAccount,
      asset_id: values.asset,
      currency_id: values.currency,
      exchange_account_id: values.exchangeAccount,
      quantity: parseFloat(values.quantity),
      type: values.type,
      best_price: parseFloat(values.bestPrice),
      fees: parseFloat(values.fees),
      vault_id: values.vault,
      created_at: values.createdAt+" +00:00",
    };
    onSubmit(payload);
  };

  const types = [
    {
      id: 0,
      type: "Sell",
    },
    {
      id: 1,
      type: "Buy",
    },
  ];

  const getAsset = async () => {
    setLoading(true);
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setAssets(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getVault = async (id: any) => {
    await postAxiosAPI("/user-vault/search", {
      "fields": [],
      "limit": 0,
      "offset": 0,
      "filters": {
          "user_id": id
      }}, true)
      .then((res: any) => {
        const vaults = res.data.data?.records || [];
        for (let i = 0; i < vaults.length; i++) {
          vaults[i].nick_name = vaults[i].nick_name || `Vault ${i + 1}`;
        }
        setUserVaults(vaults);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };


  const getUser = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setUsers(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getCurrency = async () => {
    setLoading(true);
    await getAxiosAPI("/currency")
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setCurrencies(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getExchange = async () => {
    setLoading(true);
    await getAxiosAPI("/exchange", true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data.data);
        setExchanges(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAsset();
    getCurrency();
    getExchange();
    getUser();
  }, []);

  return (
    // <Box className={classes.mainBox}>
    <Box className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h3" className={classes.formHeading}>
        {heading}
      </Typography>
      <Formik
        initialValues={{
          uid: "",
          userAccount: "",
          asset: "",
          currency: "",
          exchangeAccount: "",
          quantity: "",
          type: "",
          bestPrice: "",
          fees: "",
          vault: "",
          createdAt: "",
        }}
        validationSchema={form_schema1}
        onSubmit={(values: any) => {
          handleAPIcall(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }: any) => (
          <Grid container>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="UID"
                placeholder="UID"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.uid}
                onChange={handleChange("uid")}
                onBlur={handleBlur("uid")}
              />
              {touched.uid && errors.uid && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.uid}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={users}
                label="Select User Account"
                onSelect={(field: any, value: any) => {
                  setUserVaults([]);
                  setFieldValue(field, value);
                  getVault(value);
                  setFieldValue("vault", "");
                }}
                displayValue="email"
                formikValue="userAccount"
              />
              {touched.userAccount && errors.userAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.userAccount}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <SearchInput
                options={userVaults}
                label="Select User Vault"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="nick_name"
                formikValue="vault"
              />
              {touched.vault && errors.vault && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.vault}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={currencies}
                label="Select Currency"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="symbol"
                formikValue="currency"
              />
              {touched.currency && errors.currency && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.currency}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={exchanges}
                label="Select Exchange"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="exchangeAccount"
              />
              {touched.exchangeAccount && errors.exchangeAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.exchangeAccount}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <SearchInput
                options={assets}
                label="Select Asset"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="asset"
              />
              {touched.asset && errors.asset && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.asset}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="Quantity"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.quantity}
                onChange={handleChange("quantity")}
                onBlur={handleBlur("quantity")}
              />
              {touched.quantity && errors.quantity && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantity}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="Best Price"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.bestPrice}
                onChange={handleChange("bestPrice")}
                onBlur={handleBlur("bestPrice")}
              />
              {touched.bestPrice && errors.bestPrice && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.bestPrice}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <SearchInput
                options={types}
                label="Select Type"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="type"
                formikValue="type"
              />
              {touched.type && errors.type && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.type}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <OutlinedInput
                placeholder="Fees"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.fees}
                onChange={handleChange("fees")}
                onBlur={handleBlur("fees")}
              />
              {touched.fees && errors.fees && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.fees}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Created At"
                name="dateTime"
                type="datetime-local" // Set type to datetime-local
                value={values.validTill}
                onBlur={handleBlur("createdAt")}
                onChange={handleChange("createdAt")}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
              />
              {touched.createdAt && errors.createdAt && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.createdAt}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBox}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
      {/* </Box> */}
    </Box>
  );
};

export const get_otc_price_validation_schema = Yup.object().shape({
  uid: Yup.string().required("UID is required").strict(true),
  assetIn: Yup.string().required("Asset In is required").strict(true),
  assetOut: Yup.string().required("Asset Out is required").strict(true),
  quantityIn: Yup.number()
    .required("Quantity In is required")
    .typeError("Quantity In must be a number")
    .strict(true),
  userAccount: Yup.string().required("User Account is required").strict(true),
  exchangeAccount: Yup.string()
    .required("Exchange Account is required")
    .strict(true),
  conversion: Yup.number()
    .required("Conversion is required")
    .typeError("Conversion must be a number")
    .strict(true),
  quantityFeesIn: Yup.number()
    .required("Quantity Fees In is required")
    .typeError("Quantity Fees In must be a number")
    .strict(true),
  quantityOut: Yup.number()
    .required("Quantity Out is required")
    .typeError("Quantity Out must be a number")
    .strict(true),
  createdAt: Yup.string().required("Created At is required").strict(true),
});

export const ContactOTCForm2 = ({ heading, onSubmit }: any) => {
  const { classes } = useStyles();
  const [assets, setAssets] = useState([]);
  const [users, setUsers] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const handleAPIcall = async (values: any) => {
    const payload = {
      uid: values.uid,
      asset_in_id: values.assetIn,
      asset_out_id: values.assetOut,
      quantity_in: parseFloat(values.quantityIn),
      user_account_id: values.userAccount,
      exchange_account_id: values.exchangeAccount,
      conversion: parseFloat(values.conversion),
      quantity_fees_in: parseFloat(values.quantityFeesIn),
      quantity_out: parseFloat(values.quantityOut),
      created_at: values.createdAt + " +00:00",
    };
    onSubmit(payload);
  };

  const getAssets = async () => {
    setLoading(true);
    await getAxiosAPI("/asset", true)
      .then((res: any) => {
        setLoading(false);
        setAssets(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getUsers = async () => {
    setLoading(true);
    await getAxiosAPI("/user-account", true)
      .then((res: any) => {
        setLoading(false);
        setUsers(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getExchanges = async () => {
    setLoading(true);
    await getAxiosAPI("/exchange", true)
      .then((res: any) => {
        setLoading(false);
        setExchanges(res.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAssets();
    getUsers();
    getExchanges();
  }, []);

  return (
    // <Box className={classes.mainBox}>
    <Box className={classes.mainGrid}>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Typography variant="h3" className={classes.formHeading}>
        Offine Crypto OTC
      </Typography>
      <Formik
        initialValues={{
          uid: "",
          assetIn: "",
          assetOut: "",
          quantityIn: "",
          userAccount: "",
          exchangeAccount: "",
          conversion: "",
          quantityFeesIn: "",
          quantityOut: "",
          createdAt: "",
        }}
        validationSchema={get_otc_price_validation_schema}
        onSubmit={(values: any) => {
          handleAPIcall(values);
        }}
      >
        {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }: any) => (
          <Grid container>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="UID"
                placeholder="UID"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.uid}
                onChange={handleChange("uid")}
                onBlur={handleBlur("uid")}
              />
              {touched.uid && errors.uid && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.uid}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={assets}
                label="Select Asset In"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="assetIn"
              />
              {touched.assetIn && errors.assetIn && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.assetIn}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={assets}
                label="Select Asset Out"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="assetOut"
              />
              {touched.assetOut && errors.assetOut && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.assetOut}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="Quantity In"
                placeholder="Quantity In"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.quantityIn}
                type="number"
                onChange={handleChange("quantityIn")}
                onBlur={handleBlur("quantityIn")}
              />
              {touched.quantityIn && errors.quantityIn && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantityIn}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={users}
                label="Select User Account"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="email"
                formikValue="userAccount"
              />
              {touched.userAccount && errors.userAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.userAccount}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <SearchInput
                options={exchanges}
                label="Select Exchange Account"
                onSelect={(field: any, value: any) =>
                  setFieldValue(field, value)
                }
                displayValue="name"
                formikValue="exchangeAccount"
              />
              {touched.exchangeAccount && errors.exchangeAccount && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.exchangeAccount}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="Conversion"
                placeholder="Conversion"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.conversion}
                type="number"
                onChange={handleChange("conversion")}
                onBlur={handleBlur("conversion")}
              />
              {touched.conversion && errors.conversion && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.conversion}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="Quantity/Networth Fees"
                placeholder="Quantity/Networth Fees"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.quantityFeesIn}
                type="number"
                onChange={handleChange("quantityFeesIn")}
                onBlur={handleBlur("quantityFeesIn")}
              />
              {touched.quantityFeesIn && errors.quantityFeesIn && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantityFeesIn}
                </Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12} className={classes.gridItemPadding}>
              <TextField
                label="Quantity Out"
                placeholder="Quantity Out"
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                className={classes.inputBox}
                value={values.quantityOut}
                type="number"
                onChange={handleChange("quantityOut")}
                onBlur={handleBlur("quantityOut")}
              />
              {touched.quantityOut && errors.quantityOut && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.quantityOut}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              className={classes.gridItemPadding}
            >
              <TextField
                id="outlined-basic"
                label="Created At"
                name="dateTime"
                type="datetime-local" // Set type to datetime-local
                value={values.validTill}
                onBlur={handleBlur("createdAt")}
                onChange={handleChange("createdAt")}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
              />
              {touched.createdAt && errors.createdAt && (
                <Typography
                  variant="subtitle2"
                  align="left"
                  className={classes.errorMsg}
                >
                  {errors.createdAt}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.buttonBox}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
    // </Box>
  );
};

export const OfflineOTCForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [payload, setPayload] = useState({});

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    await postAxiosAPI(
      "/trade/offline-otc-transaction",
      JSON.stringify(payload),
      true
    )
      .then((res: any) => {
        setLoading(false);
        console.log(res.data);
        setSnackbarMessage(res.data.message);
        setShowSnackbar(true);

        if (res.data.code === 200) {
          setSeverity("success");
        } else {
          setSeverity("error");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          p={5}
          sx={{
            backgroundColor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            minWidth: 500,
            minHeight: 200,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            id="alert-dialog-title"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            Offline OTC Transaction
          </Typography>
          <Typography
            variant="body1"
            id="alert-dialog-description"
            sx={{ mb: 3, fontSize: "1.2rem" }}
          >
            Your transaction will be executed.
          </Typography>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            variant="outlined"
            sx={{ mr: 2 }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              handleSubmit(payload);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Confirm
          </Button>
        </Box>
      </Dialog>

      <ContactOTCForm1
        heading={"Offline OTC Transaction"}
        onSubmit={(payload: any) => {
          setDialogOpen(true);
          setPayload(payload);
        }}
      />
    </>
  );
};

export const ProvideOTCForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const handleSubmit = async (payload: any) => {
    await postAxiosAPI("/trade/otc-price", JSON.stringify(payload), true)
      .then((res: any) => {
        setLoading(false);
        console.log(res.data);
        setSnackbarMessage(res.data.message);
        setShowSnackbar(true);

        if (res.data.code === 200) {
          setSeverity("success");
        } else {
          setSeverity("error");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <ContactOTCForm heading={"Provide OTC"} onSubmit={handleSubmit} />;
    </>
  );
};

export const OfflineCryptoForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error">("success");

  const handleSubmit = async (payload: any) => {
    await postAxiosAPI(
      "/crypto-trade/offline-otc-transaction",
      JSON.stringify(payload),
      true
    )
      .then((res: any) => {
        setLoading(false);
        console.log(res.data);
        setSnackbarMessage(res.data.message);
        setShowSnackbar(true);

        if (res.data.code === 200) {
          setSeverity("success");
        } else {
          setSeverity("error");
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Loader isLoading={loading} />
      <MessageAlert
        showBar={showSnackbar}
        message={snackbarMessage}
        severity={severity}
        onClose={() => setShowSnackbar(false)}
      />
      <ContactOTCForm2 heading={"Provide OTC"} onSubmit={handleSubmit} />;
    </>
  );
};

export const ContactOTC: React.FC = () => {
  const tabHeading: string = "Contact OTC";
  const tabData = [
    { label: "Provide OTC", component: <ProvideOTCForm /> },
    { label: "Offline OTC Transaction", component: <OfflineOTCForm /> },
    { label: "Offline Crypto Transaction", component: <OfflineCryptoForm /> },
  ];

  return TabContent({ tabData, tabHeading });
};

const useStyles = makeStyles()((theme) => {
  return {
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    routeName: {},
    bottomText: {
      position: "absolute",
      bottom: "40px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    mainBox: {
      // backgroundColor: "#FCFBFE",
      // height: "91vh",
      // padding: "30px 50px",
      // overflowY: "auto",
      // [theme.breakpoints.down("lg")]: {
      //   height: "100%",
      //   // padding: "80px 15px",
      // },
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      // height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      // alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
