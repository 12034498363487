import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";

export const useStyles = makeStyles()((theme) => {
  return {
    personIconBox: {
      backgroundColor: pallete.primaryPurple,
      height: "120px",
      width: "120px",
      borderRadius: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "20px 0 10px 40px",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    saveBTN: {
      ...styles.webButton,
      padding: "10px 60px",
    },
    saveBtnBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    belowTexts: {
      margin: "auto 0px",
    },
    bewloBox: {
      display: "flex",
      flexDirection: "column",
    },
    belwoTextBox: {
      display: "flex",
      flexDirection: "row",
    },
    imputGrids: {
      padding: "0 10px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    deletImgBtn: {
      backgroundColor: pallete.primaryWhite,
      color: "#E13A05",
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "none",
      margin: "20px",
      "&:hover": {
        backgroundColor: pallete.primaryWhite,
      },
    },

    userImgGrid: {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    deletIcon: {
      height: "24px",
      width: "24px",
    },
    userImg: {
      height: "200px",
      width: "200px",
    },
    mainCard: {
      borderRadius: "8px",
      boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
      padding: "20px 30px",
      margin: "40px 0",
    },
    topHeadingWeb: {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
    mainBox: {
      backgroundColor: "#FCFBFE",
      height: "91vh",
      padding: "30px 70px",
      overflowY: "auto",
      [theme.breakpoints.down("lg")]: {
        height: "100%",
        padding: "80px 15px",
      },
    },
    inputFile: {
      display: "none",
    },
    avatar: {
      width: "110px",
      height: "110px",
      fontSize: "110px",
      backgroundColor: "primaryWhite",
      cursor: "pointer",
    },
  };
});
