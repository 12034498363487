import React from "react";
import { T_UseWallethubPaymentState } from "./WallethubPaymentHooks";
import {OTPVerification} from "../../public/reusables/OTPVerification";

type T_ViewProps = {
  paymentState: T_UseWallethubPaymentState;
};

export const VerifyOTP = ({ paymentState }: T_ViewProps) => {
return (
    <OTPVerification
      apiURL={"wallet-hub-transaction-request"}
      paymentState={paymentState}
      transactionId={paymentState.transactionId}
      onSuccessUpdate={"isOtpVerified"}
    />
  );
};