import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useLoader, useNotification } from "app/common";
import { Formik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { T_UseAccountStatus } from "app/hooks";
import { QUserAccount } from "app/queries";
import { pallete, styles } from "app/styles";
import { SearchInput } from "app/components/inputs";
import { getAxiosAPI } from "app/utils/axiosApiRequests";
import { motion, AnimatePresence } from "framer-motion";
import Loader from "app/components/unsorted/Loader";

const self_hosted_schema: any = Yup.object().shape({
  external_user_id: Yup.string().required("External user id is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  type: Yup.string().required("Type is required"),
  full_name: Yup.string().required("Full name is required"),
  country: Yup.string().required("Country is required"),
});

const organisation_hosted_schema: any = Yup.object().shape({
  company: Yup.string().required("Company name is required"),
});

type T_AccountInfo = {
  dob: string;
  external_user_id: string;
  type: string;
  full_name: string;
  country: string;
};


const OrganisationHosted = React.memo(({ pendingRecord, onSubmit, onSkip, classes, onPrev }: any) => {
  return (
    <Formik
      key={pendingRecord?.id}
      initialValues={{ company: "" }}
      validationSchema={organisation_hosted_schema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        values,
      }: any) => (
        <Box className={classes.formBox}>
          <TextField
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            className={classes.inputBox}
            value={values.company}
            onBlur={handleBlur("company")}
            onChange={handleChange("company")}
          />
          {touched.company && errors.company && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.company}
            </Typography>
          )}
          <DialogActions>
            <Button onClick={onPrev} color="primary">
              Back
            </Button>
            <Button onClick={onSkip} color="primary">
              Skip
            </Button>
            <Button onClick={() => handleSubmit()} color="primary">
              Send
            </Button>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
});


const SelfHosted = React.memo(({ pendingRecord, onSubmit, onSkip, classes, countries, onPrev }: any) => {
  return (
    <Formik
      key={pendingRecord?.id}
      initialValues={{
        dob: "",
        external_user_id: "",
        type: "individual",
        full_name: "",
        country: "",
      }}
      validationSchema={self_hosted_schema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
      }: any) => (
        <Box className={classes.formBox}>
          <TextField
            id="outlined-basic"
            label="External User ID"
            variant="outlined"
            className={classes.inputBox}
            value={values.external_user_id}
            onBlur={handleBlur("external_user_id")}
            onChange={handleChange("external_user_id")}
          />
          {touched.external_user_id && errors.external_user_id && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.external_user_id}
            </Typography>
          )}

          <TextField
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            className={classes.inputBox}
            value={values.full_name}
            onBlur={handleBlur("full_name")}
            onChange={handleChange("full_name")}
          />
          {touched.full_name && errors.full_name && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.full_name}
            </Typography>
          )}

          <SearchInput
            options={countries}
            label="Select Country"
            onSelect={(field: any, value: any) =>
              setFieldValue(field, value)
            }
            displayValue="name"
            formikValue="country"
          />
          {touched.country && errors.country && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.country}
            </Typography>
          )}

          <SearchInput
            options={[
              {
                id: "individual",
                name: "Individual",
              },
              {
                id: "company",
                name: "Company",
              },
            ]}
            label="Select type"
            onSelect={(field: any, value: any) =>
              setFieldValue(field, value)
            }
            displayValue="name"
            formikValue="type"
          />
          {touched.type && errors.type && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.type}
            </Typography>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%", margin: "10px 0" }}
              label="Date Of Birth"
              value={values.dob}
              format={"DD-MM-YYYY"}
              onChange={(newValue) => {
                setFieldValue(
                  "dob",
                  newValue ? newValue.toISOString() : ""
                );
              }}
            />
          </LocalizationProvider>
          {touched.dob && errors.dob && (
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.errorMsg}
            >
              {errors.dob}
            </Typography>
          )}
          <DialogActions>
            <Button onClick={onPrev} color="primary">
              Back
            </Button>
            <Button onClick={onSkip} color="primary">
              Skip
            </Button>
            <Button onClick={() => handleSubmit()} color="primary">
              Send
            </Button>
          </DialogActions>
        </Box>
      )}
    </Formik>
  );
});

const QuestionPage = React.memo(({ onAnswer, onNext, selfHosted }: any) => {

  const handleChange = (value: boolean) => {
    onAnswer(value);
  };

  return (
    <>
      <FormControl sx={{ margin: "10px 0" }}>
        <FormLabel>Is your wallet self Hosted</FormLabel>
        <RadioGroup
          value={selfHosted ? "true" : "false"}
          onChange={(e) => handleChange(e.target.value === "true")}
        >
          <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
          <FormControlLabel value={"false"} control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <DialogActions>
        <Button onClick={onNext} color="primary">Next</Button>
      </DialogActions>
    </>
  );
});

const variants = {
  enter: (direction: number) => ({ x: direction > 0 ? 1000 : -1000 }),
  center: { x: 0 },
  exit: (direction: number) => ({ x: direction < 0 ? 1000 : -1000 }),
};

const StepperContent = React.memo(({ 
  direction,
  selfHosted,
  page,
  pendingRecord,
  countries,
  classes,
  onFormSubmit,
  onOrgFormSubmit,
  onAnswer,
  onNext,
  onSkip,
  onPrev,
}: any) => {
  return (
    <motion.div
      key={page}
      custom={direction}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      style={{ width: '100%' }}
    >
      {page === 0 ? (
        <QuestionPage 
          onAnswer={(value: boolean) => onAnswer(value)}
          onNext={() => onNext()}
          selfHosted={selfHosted}
        />
      ) : selfHosted ? (
        <SelfHosted
          pendingRecord={pendingRecord}
          countries={countries}
          classes={classes}
          onSubmit={onFormSubmit}
          onSkip={onSkip}
          onPrev={onPrev}
        />
      ) : (
        <OrganisationHosted
          pendingRecord={pendingRecord}
          classes={classes}
          onSubmit={onOrgFormSubmit}
          onSkip={onSkip}
          onPrev={onPrev}
        />
      )}
    </motion.div>
  );
});

const TravelRuleTransactionAddCounterPartyData = ({ updateAccountStatus }: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const [countries, setCountries] = useState([]);
  const [pageDirection, setPageDirection] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [walletType, setWalletType] = useState(true);
  
  const { result: travelRuleStatus, isResolving, refetch : refetchTravelRuleStatus } = QUserAccount.useTravelRuleStatus(true);
  const { EXECUTE: submitData } = QUserAccount.useAddCounterPartyData();
  const notification = useNotification();
  const loader = useLoader();

  
  
  const pendingRecord = travelRuleStatus[0] || {};
  
  const handleFormSubmit = async (values: T_AccountInfo) => {
    loader.show();
    try {
      await submitData({
        payload: {
          travel_rule_transaction_id: pendingRecord.id,
          counter_party_data_from_user: {
            externalUserId: values.external_user_id,
            fullName: values.full_name,
            placeOfBirth: values.country,
            dob: values.dob,
          },
          type: values.type,
        }
      });
      notification.success("Details updated successfully!");
    } catch (error) {
      notification.error("Failed to update details!");
    } finally {
      loader.hide();
    }

    try {
      await refetchTravelRuleStatus();
      handlePageChange(0);
      setWalletType(true);
    } catch (error) {
      notification.error("Failed to fetch travel rule status!");
    }
  };
  
  const handleOrgFormSubmit = async (values: { company: string }) => {
    loader.show();
    try {
      await submitData({
        payload: {
          travel_rule_transaction_id: pendingRecord.id,
          exchange_name: values.company,
        }
      });

      notification.success("Organization details updated!");
    } catch (error) {
      notification.error("Failed to update organization details!");
    } finally {
      loader.hide();
    }
  };
  
  const handlePageChange = (newPage: number) => {
    setPageDirection(newPage > currentPage ? 1 : -1);
    setCurrentPage(newPage);
  };
  
  const handleSkip = () => updateAccountStatus({ 
    isTravelRuleTransactionAddCounterPartyDataCompleted: true 
  });
  
  useEffect(() => {
    const fetchCountries = async () => {
      loader.show();
      try {
        const response = await getAxiosAPI("/country");
        setCountries(response.data.data);
      } catch (error) {
        notification.error("Failed to fetch countries!");
      } finally {
        loader.hide();
      }
    };
    fetchCountries();
  }, []);
  
  if(travelRuleStatus && travelRuleStatus.length === 0) {
    updateAccountStatus({ isTravelRuleTransactionAddCounterPartyDataCompleted: true });
  }

  if(isResolving) {
    return <Loader isLoading={true} />;
  }

  return (
    <Dialog
      open={true}
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "90%", sm: "70%", md: "50%" },
          height: { xs: "90%", sm: "80%", md: "80%" },
          padding: "10px",
        },
      }}
    >
      <DialogTitle>Add Counter Party details</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", overflowX: "hidden" }}>
        <TransactionInfo pendingRecord={pendingRecord} classes={classes} />
        
        <Stepper activeStep={currentPage} sx={{ mx: "20%", my: "20px" }}>
          {[0, 1].map((label) => (
            <Step key={label} completed={currentPage >= label}>
              <StepLabel>{label + 1}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <AnimatePresence initial={false} custom={pageDirection}>
          <StepperContent
            direction={pageDirection}
            page={currentPage}
            selfHosted={walletType}
            pendingRecord={pendingRecord}
            countries={countries}
            classes={classes}
            onFormSubmit={handleFormSubmit}
            onOrgFormSubmit={handleOrgFormSubmit}
            onSkip={handleSkip}
            onAnswer={setWalletType}
            onNext={() => handlePageChange(1)}
            onPrev={() => handlePageChange(0)}
          />
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

const TransactionInfo = React.memo(({ pendingRecord, classes }: any) => (
  <Box className={classes.infoBox}>
    {['transaction_type', 'amount_in_euro', 'transaction_hash', 'completed_at'].map((field) => (
      <Typography key={field} className={classes.infoText}>
        {field.split('_').join(' ')}: {pendingRecord[field]}
      </Typography>
    ))}
  </Box>
));

export default TravelRuleTransactionAddCounterPartyData;

const useStyles = makeStyles()((theme) => {
  return {
    infoBox: {
      padding: "10px",
      backgroundColor: "#f5f5f5",
      marginBottom: "10px",
    },

    infoText: {
      padding: "5px",
    },

    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingInline: "10px",
    },
  };
});
