import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { RQ } from "app/hooks";
import { createAPIURL } from "app/config";
import { useLoader, useNotification } from "app/providers";
import { AnimatedOtpInput } from "app/components/inputs";
import { useTranslation } from "react-i18next";

type OTPVerificationProps = {
  apiURL: string;
  paymentState: any;
  onSuccessUpdate: string;
  transactionId: any;
};

const otpSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required"),
});

export const OTPVerification = ({
  apiURL,
  paymentState,
  transactionId,
  onSuccessUpdate,
}: OTPVerificationProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem("countdownTime");
    return savedTime ? parseInt(savedTime, 10) : 60;
  });
  const loader = useLoader();
  const notification = useNotification();
  const verifyOTP = RQ.usePostRequest({
    url: createAPIURL(apiURL, [transactionId, "verify-otp"]),
  });

  const resendOTP = RQ.usePostRequest({
    url: createAPIURL(apiURL, [transactionId, "resend-otp"]),
  });

  const sendOtp = async () => {
    loader.show();
    await resendOTP.EXECUTE({
      onSuccessFn: () => {
        setTimeLeft(60);
        notification.success(t("OTP sent successfully"));
      },
    });
    loader.hide();
  };

  const verifyOtp = async (value: any) => {
    loader.show();
    const payload = { otp_code: value.otp };
    await verifyOTP.EXECUTE({
      payload,
      onSuccessFn: () => {
        notification.success(t("OTP verified successfully"));
        paymentState.update({ [onSuccessUpdate]: true });
      },
      onErrorFn: (_, message) => {
        notification.error(t(message));
      },
    });
    loader.hide();
  };

  const goBack = () => {
    paymentState.update({ isConfirmed: false });
  };

  useEffect(() => {
    if (timeLeft <= 0) return;

    localStorage.setItem("countdownTime", timeLeft.toString());

    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const showTimer = () => {
    if (timeLeft === 0) {
      return null;
    } else if (timeLeft < 10) {
      return "00:0" + timeLeft + "Sec";
    } else {
      return "00:" + timeLeft + "Sec";
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    handleSubmit: () => void
  ) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Formik
      initialValues={{ otp: "" }}
      validationSchema={otpSchema}
      onSubmit={(values) => verifyOtp(values)}
    >
      {({ errors, touched, handleChange, handleSubmit, values }) => (
        <Box
          className={classes.formBox}
          onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
        >
          <Typography
            variant="h3"
            align="center"
            className={classes.formHeading}
          >
            {t("One time password")}
            <br /> {t("verification")}
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={{ fontWeight: "500" }}
          >
            {t("We have sent a OTP to your email")}
          </Typography>
          <Box className={classes.otpBoxInput}>
            <AnimatedOtpInput
              value={values.otp}
              onChange={handleChange("otp")}
              numInputs={6}
              shouldAutoFocus={true}
            />
          </Box>
          {touched.otp && errors.otp && (
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.errorMsg}
            >
              {errors.otp}
            </Typography>
          )}
          <Box className={classes.btnsBox}>
            <Button
              onClick={() => goBack()}
              variant="outlined"
              className={classes.submitBtnOutloined}
            >
              {t("Back")}
            </Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              className={classes.submitBtn}
            >
              {t("Submit")}
            </Button>
          </Box>
          <Box className={classes.otpBox}>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              className={
                timeLeft < 1 ? classes.resentOtpText : classes.resentOtpTextD
              }
              onClick={() => (timeLeft < 1 ? sendOtp() : null)}
            >
              {t("Resend OTP")}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              sx={{ margin: "auto 20px", width: "50px" }}
            >
              {showTimer()}
            </Typography>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    otpBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },

    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    resentOtpTextD: {
      textDecorationLine: "underline",
      padding: "20px 0",
      color: "grey",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      position: "relative",
    },
  };
});
