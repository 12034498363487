import React, { useState } from "react";
import {
  Box,
  Button, Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useStyles } from "./settingStyle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import * as Yup from "yup";

const passwrd_schema = Yup.object().shape({
  oldPass: Yup.string().required("Current password is required"),
  newPass: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Password must be 8-16 characters long, with at least one uppercase letter, one number, and one special character"
    ),
  confirmPass: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPass"), ""], "Passwords must match"),
});

const ChangePasswordForm = ({ updatePassword, decoded }:any) => {
  const { classes } = useStyles();

  const [showPassword, setShowPassword] = useState({
    oldPas: false,
    newPas: false,
    confirmPass: false,
  });

  const handleClickShowPassword = (value:string ) => {
    setShowPassword((pre:any) => ({ ...pre, [value]: !pre[value] }));
  };

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        oldPass: "",
        newPass: "",
        confirmPass: "",
      }}
      validationSchema={passwrd_schema}
      enableReinitialize={true}
      onSubmit={(values) => {
        updatePassword(values);
      }}
    >
      {({
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          values,
        }: any) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} className={classes.imputGrids}>
              <Typography color="primary" variant="body2">
                Current Password<span style={{ color: "#E13A05" }}>*</span>
              </Typography>
              <FormControl className={classes.inputBox} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  label="Password"
                  type={showPassword.oldPas ? "text" : "password"}
                  value={values.oldPass}
                  onChange={handleChange("oldPass")}
                  onBlur={handleBlur("oldPass")}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("oldPas")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.oldPas ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.oldPass && errors.oldPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.oldPass}
                          </Typography>
                        )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} className={classes.imputGrids}>
              <Typography color="primary" variant="body2">
                New Password<span style={{ color: "#E13A05" }}>*</span>
              </Typography>
              <FormControl className={classes.inputBox} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  label="New Password"
                  type={showPassword.newPas ? "text" : "password"}
                  value={values.newPass}
                  onChange={handleChange("newPass")}
                  onBlur={handleBlur("newPass")}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("newPas")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.newPas ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.newPass && errors.newPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.newPass}
                          </Typography>
                        )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} className={classes.imputGrids}>
              <Typography color="primary" variant="body2">
                Confirm Password<span style={{ color: "#E13A05" }}>*</span>
              </Typography>
              <FormControl className={classes.inputBox} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  type={showPassword.confirmPass ? "text" : "password"}
                  value={values.confirmPass}
                  onChange={handleChange("confirmPass")}
                  onBlur={handleBlur("confirmPass")}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("confirmPass")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.confirmPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.confirmPass && errors.confirmPass && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.confirmPass}
                          </Typography>
                        )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.saveBtnBox}>
                <Button
                  disabled={decoded.session.auth_type !== 1}
                  type="submit"
                  variant="contained"
                  className={classes.saveBTN}
                >
                  Save Password
                </Button>
              </Box>
            </Grid>
          </Grid>
          {/* Checks */}
        <Box className={classes.bewloBox}>
          <Typography
            color="primary"
            variant="body2"
            sx={{ fontWeight: "600", padding: "10px" }}
          >
            Notifications:
          </Typography>
          <Box className={classes.belwoTextBox}>
            <Checkbox defaultChecked />
            <Typography className={classes.belowTexts}>
              Changes made to your account
            </Typography>
          </Box>
          <Box className={classes.belwoTextBox}>
            <Checkbox defaultChecked />
            <Typography className={classes.belowTexts}>
              Marketing and promo offers
            </Typography>
          </Box>
          <Box className={classes.belwoTextBox}>
            <Checkbox defaultChecked />
            <Typography className={classes.belowTexts}>
              Security alerts
            </Typography>
          </Box>
        </Box>
        </form>
      )}
    </Formik>
  );
};
export default ChangePasswordForm;