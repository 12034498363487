import React from "react";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import { OTPVerification } from "../../public/reusables/OTPVerification";

type T_ViewProps = {
  paymentState: T_UseCryptohubPaymentState;
};

export const VerifyOTP = ({ paymentState }: T_ViewProps) => {
  return (
    <OTPVerification
      apiURL={"crypto-hub-transaction-request"}
      paymentState={paymentState}
      transactionId={paymentState.transactionId}
      onSuccessUpdate={"isOtpVerified"}
    />
  );
};
