import React from "react";
import { T_UseMoneyhubPaymentState } from "./MoneyhubPaymentHooks";
import {OTPVerification} from "../../public/reusables/OTPVerification";

type T_ViewProps = {
  paymentState: T_UseMoneyhubPaymentState;
};

export const VerifyOTP = ({ paymentState }: T_ViewProps) => {
  return (
    <OTPVerification
      apiURL={"moneyhub-transaction-request"}
      paymentState={paymentState}
      transactionId={paymentState.transactionId}
      onSuccessUpdate={"isOtpVerified"}
    />
  );
};