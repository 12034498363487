import React from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import { useStyles } from "./settingStyle";
import { Formik } from "formik";
import {MuiPhone} from "../../../../app/components/inputs/fields/MobileNumberWIthFlag";
import * as Yup from "yup";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const form_schema = Yup.object().shape({
  fullname: Yup.string().required("Full name is required"),
  // email: Yup.string().required("Email is required"),
  dob: Yup.string()
    .required("Date of birth is required")
    .test("dob", "You must be at least 18 years old", (value) => {
      const today = dayjs();
      const dob = dayjs(value);
      const age = today.diff(dob, "year");
      return age >= 18;
    }),
  companyName: Yup.string().required("Country is required"),
  countryCity: Yup.string().required("Company name is required"),
  address: Yup.string().required("Address is required"),
  mobileNumber: Yup.string().required("Phone Number is required"),
});

const ChangeProfileDetailsForm = ({ userDetails, updateUserDetails, decoded }:any) => {
  const { classes } = useStyles();
  userDetails.dob = userDetails.dob ? dayjs(userDetails.dob) : "";
  return (
    <Formik
      initialValues={userDetails}
      validationSchema={form_schema}
      enableReinitialize={true}
      onSubmit={(values):any => {
          // values.dob = values.dob.split("T")[0];
        console.log("Profile details form submitted:", values);
        updateUserDetails(values);
      }}
    >
      {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
        }:any) => (
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Full Name<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Full Name"
                    variant={"outlined"}
                    className={classes.inputBox}
                    value={values.fullname}
                    onChange={handleChange("fullname")}
                    onBlur={handleBlur("fullname")}
                    autoComplete="off"
                  />
                    {touched.fullname && errors.fullname && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.fullname}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Email<span style={{ color: "#E13A05" }}></span>
                  </Typography>
                  <TextField
                    label="Email"
                    variant="outlined"
                    disabled={true}
                    className={classes.inputBox}
                    value={values.email}
                    onBlur={handleBlur("email")}
                  />
                    {touched.email && errors.email && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.email}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Company Name<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.companyName}
                    onChange={handleChange("companyName")}
                    onBlur={handleBlur("companyName")}
                  />{touched.companyName && errors.companyName && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.companyName}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Phone Number<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <MuiPhone
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onChange={handleChange("mobileNumber")}
                    onBlur={handleBlur("mobileNumber")}
                    style={{
                      "--react-international-phone-flag-width": "40px",
                      "--react-international-phone-flag-height": "32px",
                      "--react-international-phone-height": "50px",
                      "--react-international-phone-width": "full",
                      "--react-international-phone-font-size": "15px",
                      paddingLeft: "0px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      width: "102%",
                    }as React.CSSProperties}
                  />
                    {touched.mobileNumber && errors.mobileNumber && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.mobileNumber}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Country<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Country"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.countryCity}
                    onChange={handleChange("countryCity")}
                    onBlur={handleBlur("countryCity")}
                  />
                    {touched.countryCity && errors.countryCity && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.countryCity}
                          </Typography>
                        )}
                </Grid>
                <Grid item lg={6} xs={12} className={classes.imputGrids}>
                  <Typography color="primary" variant="body2">
                    Address<span style={{ color: "#E13A05" }}>*</span>
                  </Typography>
                  <TextField
                    label="Address"
                    variant="outlined"
                    className={classes.inputBox}
                    value={values.address}
                    onChange={handleChange("address")}
                    onBlur={handleBlur("address")}
                  />
                    {touched.address && errors.address && (
                          <Typography
                            variant="subtitle2"
                            align="left"
                            className={classes.errorMsg}
                          >
                            {errors.address}
                          </Typography>
                        )}
                </Grid>
                <Grid
                    item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.imputGrids}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "100%", margin: "10px 0" }}
                          label={"Date Of Birth"}
                          value={values.dob}
                          onChange={(newValue) => {
                            // Update Formik's state for the 'dob' field with the new date value
                            setFieldValue(
                              "dob",
                              newValue ? newValue.toISOString() : ""
                            );
                          }}
                        />
                      </LocalizationProvider>
                      {touched.dob && errors.dob && (
                        <Typography
                          variant="subtitle2"
                          align="left"
                          className={classes.errorMsg}
                        >
                            {errors.dob}
                        </Typography>
                      )}
                    </Grid>
                <Grid item xs={12}>
                  <Box className={classes.saveBtnBox}>
                    <Button
                      disabled={decoded.session.auth_type !== 1}
                      type="submit"
                      variant="contained"
                      className={classes.saveBTN}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
          </form>
      )}
    </Formik>
  );
};
export default ChangeProfileDetailsForm